import { PipeTransform } from '@angular/core';
var NumberWithCommasPipe = /** @class */ (function () {
    function NumberWithCommasPipe() {
    }
    NumberWithCommasPipe.prototype.transform = function (input) {
        return new Intl.NumberFormat().format(input);
    };
    return NumberWithCommasPipe;
}());
export { NumberWithCommasPipe };
