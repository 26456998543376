import { OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(sidebarService, menuService, themeService, userService, breakpointService) {
        this.sidebarService = sidebarService;
        this.menuService = menuService;
        this.themeService = themeService;
        this.userService = userService;
        this.breakpointService = breakpointService;
        this.destroy$ = new Subject();
        this.userPictureOnly = false;
        this.themes = [
            {
                value: 'default',
                name: 'Light',
            },
            {
                value: 'dark',
                name: 'Dark',
            },
            {
                value: 'cosmic',
                name: 'Cosmic',
            },
            {
                value: 'corporate',
                name: 'Corporate',
            },
        ];
        this.currentTheme = 'default';
        this.userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentTheme = this.themeService.currentTheme;
        this.userService.getUsers()
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (users) { return _this.user = users.nick; });
        var xl = this.breakpointService.getBreakpointsMap().xl;
        this.themeService.onMediaQueryChange()
            .pipe(map(function (_a) {
            var currentBreakpoint = _a[1];
            return currentBreakpoint.width < xl;
        }), takeUntil(this.destroy$))
            .subscribe(function (isLessThanXl) { return _this.userPictureOnly = isLessThanXl; });
        this.themeService.onThemeChange()
            .pipe(map(function (_a) {
            var name = _a.name;
            return name;
        }), takeUntil(this.destroy$))
            .subscribe(function (themeName) { return _this.currentTheme = themeName; });
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    HeaderComponent.prototype.changeTheme = function (themeName) {
        this.themeService.changeTheme(themeName);
    };
    HeaderComponent.prototype.toggleSidebar = function () {
        this.sidebarService.toggle(true, 'menu-sidebar');
        return false;
    };
    HeaderComponent.prototype.navigateHome = function () {
        this.menuService.navigateHome();
        return false;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
