import { NbIconLibraries } from "@nebular/theme";
import * as i0 from "@angular/core";
import * as i1 from "@nebular/theme";
var IconLoadService = /** @class */ (function () {
    function IconLoadService(library) {
        this.library = library;
    }
    IconLoadService.prototype.load = function () {
        this.library.registerSvgPack('oil', {
            'loading': require('!!raw-loader!../../assets/images/directories/loading.svg'),
            'all-done': require('!!raw-loader!../../assets/images/directories/all-done.svg'),
            'providers': require('!!raw-loader!../../assets/images/directories/providers.svg'),
            'bases': require('!!raw-loader!../../assets/images/directories/bases.svg'),
        });
    };
    IconLoadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IconLoadService_Factory() { return new IconLoadService(i0.ɵɵinject(i1.NbIconLibraries)); }, token: IconLoadService, providedIn: "root" });
    return IconLoadService;
}());
export { IconLoadService };
