import { ExtraOptions, Routes } from '@angular/router';
import { AuthComponent, LogoutComponent, PermissionGuard, TokenComponent } from 'oil-auth';
import { HandbookPermissionKeysEnum } from "./models/types/handbook-permission-keys.enum";
var ɵ0 = { permission: HandbookPermissionKeysEnum.HANDBOOK }, ɵ1 = function () { return import("./pages/pages.module.ngfactory")
    .then(function (m) { return m.PagesModuleNgFactory; }); };
var routes = [
    {
        path: '',
        canActivate: [PermissionGuard],
        data: ɵ0,
        loadChildren: ɵ1,
    },
    {
        path: 'authorization',
        component: AuthComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: 'token',
        component: TokenComponent,
    },
    { path: '**', redirectTo: '' },
];
var config = {
    useHash: false,
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
