import * as signalR from "@microsoft/signalr";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
var SignalRService = /** @class */ (function () {
    function SignalRService() {
        this.pipeline = new Subject();
        this.buildConnection();
    }
    Object.defineProperty(SignalRService.prototype, "onReceive", {
        get: function () {
            return this.pipeline;
        },
        enumerable: true,
        configurable: true
    });
    SignalRService.prototype.buildConnection = function () {
        var _this = this;
        var hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(environment.baseUrl + "/hubs/notifications")
            .withAutomaticReconnect([5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000])
            .build();
        this.restart(hubConnection);
        hubConnection.onclose(function () {
            _this.restart(hubConnection);
        });
    };
    SignalRService.prototype.restart = function (connection) {
        var _this = this;
        connection.start().then(function () {
            connection.on("Entity", function (data) {
                _this.pipeline.next(data);
            });
        });
    };
    SignalRService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignalRService_Factory() { return new SignalRService(); }, token: SignalRService, providedIn: "root" });
    return SignalRService;
}());
export { SignalRService };
export var SignalEntityEvent;
(function (SignalEntityEvent) {
    SignalEntityEvent["Created"] = "Created";
    SignalEntityEvent["Updated"] = "Updated";
    SignalEntityEvent["Removed"] = "Removed";
    SignalEntityEvent["Reorder"] = "Reorder";
    SignalEntityEvent["Archived"] = "Archived";
})(SignalEntityEvent || (SignalEntityEvent = {}));
export var SignalEntityType;
(function (SignalEntityType) {
    SignalEntityType["Employee"] = "Employee";
    SignalEntityType["CatalogGroup"] = "CatalogGroup";
    SignalEntityType["Product"] = "Product";
    SignalEntityType["ClientType"] = "ClientType";
    SignalEntityType["Client"] = "Client";
    SignalEntityType["Carrier"] = "Carrier";
    SignalEntityType["Supplier"] = "Supplier";
    SignalEntityType["ProductRule"] = "ProductRule";
    SignalEntityType["LegalEntity"] = "LegalEntity";
    SignalEntityType["SettlementAccount"] = "SettlementAccount";
    SignalEntityType["ClientAddress"] = "ClientAddress";
    SignalEntityType["Basis"] = "Basis";
    SignalEntityType["BasisGroup"] = "BasisGroup";
    SignalEntityType["Driver"] = "Driver";
    SignalEntityType["CarSection"] = "CarSection";
    SignalEntityType["CarAxle"] = "CarAxle";
    SignalEntityType["CarTractor"] = "CarTractor";
    SignalEntityType["CarTrailerSection"] = "CarTrailerSection";
    SignalEntityType["Car"] = "Car";
    SignalEntityType["CarTrailer"] = "CarTrailer";
    SignalEntityType["LegalEntityLinkToOwn"] = "LegalEntityLinkToOwn";
    SignalEntityType["Depot"] = "Depot";
    SignalEntityType["Tank"] = "Tank";
})(SignalEntityType || (SignalEntityType = {}));
