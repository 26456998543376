import { ModuleWithProviders } from '@angular/core';
import { UserService } from './users.service';
var SERVICES = [
    UserService,
];
var MockDataModule = /** @class */ (function () {
    function MockDataModule() {
    }
    MockDataModule.forRoot = function () {
        return {
            ngModule: MockDataModule,
            providers: SERVICES.slice(),
        };
    };
    return MockDataModule;
}());
export { MockDataModule };
