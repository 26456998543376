import { of, Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { SignalEntityEvent, SignalEntityType, SignalRService } from "./signal-r.service";
import { OilHandbookApiClientService } from "oil-handbook-api";
import { StorageEnum, StorageService, UserService } from "oil-auth";
import { PermissionLevel } from "../models/types/employee-access-settings.enum";
import { HandbookPermissionKeysEnum } from "../models/types/handbook-permission-keys.enum";
import { OilAuthApiClientService } from "oil-auth-api";
import * as i0 from "@angular/core";
import * as i1 from "./signal-r.service";
import * as i2 from "oil-handbook-api";
import * as i3 from "oil-auth";
import * as i4 from "oil-auth-api";
var ChangesPipelineService = /** @class */ (function () {
    function ChangesPipelineService(signalRService, api, userService, apiClientService, storageService) {
        var _this = this;
        this.signalRService = signalRService;
        this.api = api;
        this.userService = userService;
        this.apiClientService = apiClientService;
        this.storageService = storageService;
        this.processEmployee = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.employees.get(container.id)
                        .subscribe(function (data) {
                        _this.fire(container, data);
                    });
                    break;
            }
        };
        this.processCatalogGroup = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.catalog.groups.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processProduct = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.catalog.products.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processClients = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.clients.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processClientType = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.clients.types.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processCarrier = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.carriers.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processSupplier = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.suppliers.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processProductRules = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.suppliers.productRules.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processLegalEntities = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.legalEntities.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processAccounts = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.legalEntities.accounts.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processClientAddress = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.clients.addresses.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processBase = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.bases.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processBasisGroup = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.bases.groups.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processDriver = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.carriers.drivers.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processCarAxle = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.carriers.cars.axles.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processCarTractor = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.carriers.cars.tractors.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processCarTrailer = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.carriers.cars.trailers.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processCarTrailerSection = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.carriers.cars.trailers.sections.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processCar = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.carriers.cars.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processLegalEntityLinkToOwn = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.legalEntities.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processDepot = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.depots.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.processTank = function (container) {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    _this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    _this.api.depots.tanks.get(container.id)
                        .subscribe(function (data) { return _this.fire(container, data); });
                    break;
            }
        };
        this.pipeline = new Subject();
    }
    ChangesPipelineService.prototype.init = function () {
        var _this = this;
        this.signalSubscription = this.signalRService.onReceive.subscribe(function (model) {
            _this.process(model);
        });
    };
    ChangesPipelineService.prototype.permissionByKey = function (key, container, callback) {
        var permission = this.userService.checkPermission(key, PermissionLevel.Restricted);
        if (!permission) {
            callback(container);
        }
    };
    ChangesPipelineService.prototype.processEmployeeWithData = function (key, container) {
        var _this = this;
        this.getUserNewData(container)
            .subscribe(function () { return _this.permissionByKey(key, container, _this.processEmployee); });
    };
    ChangesPipelineService.prototype.getUserNewData = function (container) {
        var _this = this;
        if (this.userService.userData && this.userService.userData.user &&
            container.id === this.userService.userData.user.employee_id) {
            var token = this.storageService.getValue(StorageEnum.token);
            var key = token && token.access && token.access.token || '';
            return this.apiClientService.tokens.self(key)
                .pipe(tap(function (response) {
                _this.userService.setUserData(response);
            }));
        }
        return of(null);
    };
    ChangesPipelineService.prototype.process = function (container) {
        console.log('container', container);
        switch (container.type) {
            case SignalEntityType.Employee:
                this.processEmployeeWithData(HandbookPermissionKeysEnum.EMPLOYEE, container);
                break;
            case SignalEntityType.CatalogGroup:
                this.permissionByKey(HandbookPermissionKeysEnum.CATALOGGROUPS, container, this.processCatalogGroup);
                break;
            case SignalEntityType.Product:
                this.permissionByKey(HandbookPermissionKeysEnum.CATALOGPRODUCTS, container, this.processProduct);
                break;
            case SignalEntityType.ClientType:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTSTYPES, container, this.processClientType);
                break;
            case SignalEntityType.Client:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTS, container, this.processClients);
                break;
            case SignalEntityType.Carrier:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERS, container, this.processCarrier);
                break;
            case SignalEntityType.Supplier:
                this.permissionByKey(HandbookPermissionKeysEnum.SUPPLIERS, container, this.processSupplier);
                break;
            case SignalEntityType.ProductRule:
                this.permissionByKey(HandbookPermissionKeysEnum.SUPPLIERSPRODUCTRULES, container, this.processProductRules);
                break;
            case SignalEntityType.LegalEntity:
                this.permissionByKey(HandbookPermissionKeysEnum.LEGALENTITIES, container, this.processLegalEntities);
                break;
            case SignalEntityType.SettlementAccount:
                this.permissionByKey(HandbookPermissionKeysEnum.ACCOUNTS, container, this.processAccounts);
                break;
            case SignalEntityType.ClientAddress:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTSADDRESSES, container, this.processClientAddress);
                break;
            case SignalEntityType.Basis:
                this.permissionByKey(HandbookPermissionKeysEnum.BASES, container, this.processBase);
                break;
            case SignalEntityType.BasisGroup:
                this.permissionByKey(HandbookPermissionKeysEnum.BASESGROUPS, container, this.processBasisGroup);
                break;
            case SignalEntityType.Driver:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSDRIVERS, container, this.processDriver);
                break;
            case SignalEntityType.CarAxle:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSCARSAXLES, container, this.processCarAxle);
                break;
            case SignalEntityType.CarTractor:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRACTORS, container, this.processCarTractor);
                break;
            case SignalEntityType.CarTrailer:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRAILERS, container, this.processCarTrailer);
                break;
            case SignalEntityType.CarTrailerSection:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRAILERSSECTIONS, container, this.processCarTrailerSection);
                this.processCarTrailerSection(container);
                break;
            case SignalEntityType.Car:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSCARS, container, this.processCar);
                break;
            case SignalEntityType.LegalEntityLinkToOwn:
                this.permissionByKey(HandbookPermissionKeysEnum.OWNLEGALENTITIES, container, this.processLegalEntityLinkToOwn);
                break;
            case SignalEntityType.Depot:
                this.permissionByKey(HandbookPermissionKeysEnum.HANDBOOKDEPOT, container, this.processDepot);
                break;
            case SignalEntityType.Tank:
                this.permissionByKey(HandbookPermissionKeysEnum.HANDBOOKDEPOT, container, this.processTank);
                break;
        }
    };
    ChangesPipelineService.prototype.fire = function (container, data) {
        if (data === void 0) { data = null; }
        this.pipeline.next({
            message: container,
            data: data
        });
    };
    ChangesPipelineService.prototype.onEmployeeAdd = function () {
        return this.subscribe([], [SignalEntityType.Employee], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onEmployeeUpdate = function () {
        return this.subscribe([], [SignalEntityType.Employee], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onEmployeeUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Employee], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCatalogGroupAdd = function () {
        return this.subscribe([], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onCatalogGroupUpdate = function () {
        return this.subscribe([], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCatalogGroupUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCatalogGroupDelete = function () {
        return this.subscribe([], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onProductAdd = function () {
        return this.subscribe([], [SignalEntityType.Product], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onProductUpdate = function () {
        return this.subscribe([], [SignalEntityType.Product], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onProductUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Product], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onProductDelete = function () {
        return this.subscribe([], [SignalEntityType.Product], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onClientTypeAdd = function () {
        return this.subscribe([], [SignalEntityType.ClientType], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onClientTypeUpdate = function () {
        return this.subscribe([], [SignalEntityType.ClientType], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onClientTypeUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.ClientType], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onClientTypeDelete = function () {
        return this.subscribe([], [SignalEntityType.ClientType], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onClientAdd = function () {
        return this.subscribe([], [SignalEntityType.Client], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onClientUpdate = function () {
        return this.subscribe([], [SignalEntityType.Client], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onClientUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Client], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onClientDelete = function () {
        return this.subscribe([], [SignalEntityType.Client], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onCarrierAdd = function () {
        return this.subscribe([], [SignalEntityType.Carrier], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onCarrierUpdate = function () {
        return this.subscribe([], [SignalEntityType.Carrier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarrierUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Carrier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarrierDelete = function () {
        return this.subscribe([], [SignalEntityType.Carrier], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onCarTractorAdd = function () {
        return this.subscribe([], [SignalEntityType.CarTractor], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onCarTractorUpdate = function () {
        return this.subscribe([], [SignalEntityType.CarTractor], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarTractorUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarTractor], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarTractorDelete = function () {
        return this.subscribe([], [SignalEntityType.CarTractor], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onCarAdd = function () {
        return this.subscribe([], [SignalEntityType.Car], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onCarUpdate = function () {
        return this.subscribe([], [SignalEntityType.Car], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Car], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarDelete = function () {
        return this.subscribe([], [SignalEntityType.Car], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onCarTrailerAdd = function () {
        return this.subscribe([], [SignalEntityType.CarTrailer], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onCarTrailerUpdate = function () {
        return this.subscribe([], [SignalEntityType.CarTrailer], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarTrailerUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarTrailer], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarTrailerDelete = function () {
        return this.subscribe([], [SignalEntityType.CarTrailer], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onSupplierAdd = function () {
        return this.subscribe([], [SignalEntityType.Supplier], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onSupplierUpdate = function () {
        return this.subscribe([], [SignalEntityType.Supplier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onSupplierUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Supplier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onSupplierDelete = function () {
        return this.subscribe([], [SignalEntityType.Supplier], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onProductRuleAdd = function () {
        return this.subscribe([], [SignalEntityType.ProductRule], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onProductRuleUpdate = function () {
        return this.subscribe([], [SignalEntityType.ProductRule], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onProductRuleUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.ProductRule], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onProductRuleDelete = function () {
        return this.subscribe([], [SignalEntityType.ProductRule], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onLegalEntityAdd = function () {
        return this.subscribe([], [SignalEntityType.LegalEntity], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onLegalEntityLinkToOwnAdd = function () {
        return this.subscribe([], [SignalEntityType.LegalEntityLinkToOwn], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onLegalEntityUpdate = function () {
        return this.subscribe([], [SignalEntityType.LegalEntity], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onLegalEntityUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.LegalEntity], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onLegalEntityDelete = function () {
        return this.subscribe([], [SignalEntityType.LegalEntity], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onSettlementAccountAdd = function () {
        return this.subscribe([], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onSettlementAccountUpdate = function () {
        return this.subscribe([], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onSettlementAccountUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onSettlementAccountDelete = function () {
        return this.subscribe([], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onClientAddressAdd = function () {
        return this.subscribe([], [SignalEntityType.ClientAddress], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onClientAddressUpdate = function () {
        return this.subscribe([], [SignalEntityType.ClientAddress], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onClientAddressUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.ClientAddress], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onClientAddressDelete = function () {
        return this.subscribe([], [SignalEntityType.ClientAddress], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onBasisAdd = function () {
        return this.subscribe([], [SignalEntityType.Basis], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onBasisUpdate = function () {
        return this.subscribe([], [SignalEntityType.Basis], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onBasisUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Basis], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onBasisDelete = function () {
        return this.subscribe([], [SignalEntityType.Basis], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onBasisGroupAdd = function () {
        return this.subscribe([], [SignalEntityType.BasisGroup], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onBasisGroupUpdate = function () {
        return this.subscribe([], [SignalEntityType.BasisGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onBasisGroupUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.BasisGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onBasisGroupDelete = function () {
        return this.subscribe([], [SignalEntityType.BasisGroup], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onDriverAdd = function () {
        return this.subscribe([], [SignalEntityType.Driver], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onDriverUpdate = function () {
        return this.subscribe([], [SignalEntityType.Driver], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onDriverUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Driver], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onDriverDelete = function () {
        return this.subscribe([], [SignalEntityType.Driver], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onCarAxleAdd = function () {
        return this.subscribe([], [SignalEntityType.CarAxle], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onCarAxleUpdate = function () {
        return this.subscribe([], [SignalEntityType.CarAxle], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarAxleUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarAxle], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCarAxleDelete = function () {
        return this.subscribe([], [SignalEntityType.CarAxle], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onTrailerSectionAdd = function () {
        return this.subscribe([], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onTrailerSectionUpdate = function () {
        return this.subscribe([], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onCTrailerSectionUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onTrailerSectionDelete = function () {
        return this.subscribe([], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onTankAdd = function () {
        return this.subscribe([], [SignalEntityType.Tank], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onDepotAdd = function () {
        return this.subscribe([], [SignalEntityType.Depot], [SignalEntityEvent.Created]);
    };
    ChangesPipelineService.prototype.onDepotUpdate = function () {
        return this.subscribe([], [SignalEntityType.Depot], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onDepotUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Depot], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onDepotDelete = function () {
        return this.subscribe([], [SignalEntityType.Depot], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.onTankUpdate = function () {
        return this.subscribe([], [SignalEntityType.Tank], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onTankUpdateById = function (loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Tank], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    };
    ChangesPipelineService.prototype.onTankDelete = function () {
        return this.subscribe([], [SignalEntityType.Tank], [SignalEntityEvent.Removed]);
    };
    ChangesPipelineService.prototype.subscribe = function (ids, types, events) {
        return this.pipeline.pipe(filter(function (container) {
            if (0 != ids.length && !ids.includes(container.message.id)) {
                return false;
            }
            if (0 != types.length && !types.includes(container.message.type)) {
                return false;
            }
            if (0 != events.length && !events.includes(container.message.event)) {
                return false;
            }
            return true;
        }));
    };
    ChangesPipelineService.prototype.destroy = function () {
        this.signalSubscription.unsubscribe();
    };
    ChangesPipelineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChangesPipelineService_Factory() { return new ChangesPipelineService(i0.ɵɵinject(i1.SignalRService), i0.ɵɵinject(i2.OilHandbookApiClientService), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.OilAuthApiClientService), i0.ɵɵinject(i3.StorageService)); }, token: ChangesPipelineService, providedIn: "root" });
    return ChangesPipelineService;
}());
export { ChangesPipelineService };
