import { environment } from "../environments/environment";
import { EnvLibraryConfig } from "oil-auth";
var oilAuthConfig = {
    host: environment.authApi,
    login: environment.authUrl
};
var ɵ0 = { host: environment.authApi }, ɵ1 = { host: environment.baseUrl };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
