import { OnDestroy, OnInit } from '@angular/core';
import { ChangesPipelineService } from "./services/changes-pipeline.service";
import { IconLoadService } from "./services/icon-load.service";
import { TokenService } from "oil-auth";
var AppComponent = /** @class */ (function () {
    function AppComponent(changesPipeline, iconLoader, tokenService) {
        this.changesPipeline = changesPipeline;
        this.iconLoader = iconLoader;
        this.tokenService = tokenService;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.iconLoader.load();
        this.changesPipeline.init();
        this.tokenService.startWatch();
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.changesPipeline.destroy();
        this.tokenService.startWatch();
    };
    return AppComponent;
}());
export { AppComponent };
